import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login/Login';
import Forgot from './components/ForgotPassword/Forgot';
import NewPassword from './components/ForgotPassword/NewPassword';
import Success from './components/ForgotPassword/Success';
import useEagerConnect from './hooks/useEagerConnect';


function App() {
  useEagerConnect();
  return (
    <>
      <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="dark" position="top-center" />
      <Router>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/forgot' component={Forgot} />
          <Route exact path='/reset-password' component={NewPassword} />
          <Route exact path='/success' component={Success} />
        </Switch>
      </Router>

     
    </>
  );
}

export default App;
